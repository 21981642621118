import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import client from "../../helpers/feathersClient.js";
import defines from "../../helpers/defines.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function StartingPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    queryCompetitions();
  }, []);

  function queryCompetitions() {
    client
      .service("competitions")
      .find({
        query: {
          $sort: {
            order: 1
          }
        }
      })
      .then((comptetions) => {
        setCompetitions(comptetions.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="La Kompeti"
        //rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {competitions.map((competition) => (
              <GridItem key={competition._id} xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>{competition.name}</h4>
                    </CardHeader>
                    <CardFooter className={classes.cardFooter}>
                      {competition.type ===
                        defines.competitionTypes
                          .COMPETITION_MIXED_PLAYERS_PERCENTAGE && (
                        <Link to={"/classification-page/" + competition._id}>
                          <Button simple color="primary" size="lg">
                            Seleccionar
                          </Button>
                        </Link>
                      )}
                      {competition.type ===
                        defines.competitionTypes.COMPETITION_PHASES_PAIRS && (
                        <Link
                          to={"/phase-classification-page/" + competition._id}
                        >
                          <Button simple color="primary" size="lg">
                            Seleccionar
                          </Button>
                        </Link>
                      )}
                      {competition.type ===
                        defines.competitionTypes.COMPETITION_PHASES_MIXED_PLAYERS_PERCENTAGE && (
                        <Link
                          to={"/mixed-phase-classification-page/" + competition._id}
                        >
                          <Button simple color="primary" size="lg">
                            Seleccionar
                          </Button>
                        </Link>
                      )}
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
